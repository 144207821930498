// import { IconButton } from "@material-ui/core";
import React, { useState, useEffect } from "react";
// import icon1 from "../../src/static/pass-view-white-asc.png";
import TerminosAdvices from "../TerminosAdvices/TerminosAdvices";
import "./RecolectDataStart.scss";
import { infoMessage } from "../utils/ErrorHandler/errorHandler";
import { useHistory } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import StorageManager from "../utils/StorageManager";

function RecolectDataStart(props) {
  let history = useHistory();

  const [data2, setData] = useState();
  const [selectdata, setSelectData] = useState("1");
  // const needsRut = props.dataRepo.needsRut;
  const needsRut = props.dataRepo.needsRut;
  const docTypes = props.dataRepo.supportedDocTypes;

  const [needsRedirect, setNeedsRedirect] = useState(false);

  const [docType, setDocType] = useState("CEDULA");

  const [index, setIndex] = useState(0);

  const [storageManager, _] = useState(new StorageManager());

  const [needsPhone, setNeedsPhone] = useState(true);
  const [needsName, setNeedsName] = useState(false);
  const [needsLastName, setNeedsLastName] = useState(true);

  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [userDoc, setUserDoc] = useState("");
  const [name, setName] = useState("");

  const query = useQuery();
  const UrlCode = query.get("code");
  // console.log('UrlCode',UrlCode)
  function blockDataEntry(data) {
    props.setCanEdit(false);
    // if (!data.lastName) {
    //   // document.getElementById("nombreUser").value = " ";
    //   // setLastName(" ");
    // } else {
    //   if (document.getElementById("userLastName"))
    //     document.getElementById("userLastName").disabled = true;
    // }

    // if (!data.identification) {
    //   document.getElementById("cedulaUser").value = " ";
    // } else {
    //   document.getElementById("cedulaUser").disabled = true;
    // }

    // if (!data.email) {
    //   document.getElementById("correoUser").value = " ";
    // } else {
    //   document.getElementById("correoUser").disabled = true;
    // }

    // if (needsPhone) {
    //   if (!data.cellphone) {
    //     setPhone(" ");
    //   } else {
    //     document.getElementById("celularUser").disabled = true;
    //   }
    // }

    // if (data.naturalPerson === true || data.naturalPerson === false) {
    //   document.getElementById("personSelector").disabled = true;
    // }

    // if (needsRut && data.naturalPerson) {
    //   document.getElementById("rutUser").disabled = true;
    // } else if (!data.naturalPerson) {
    //   document.getElementById("nitUser").disabled = true;
    // }
  }

  useEffect(() => {
    //Skip if not needed
    let project = storageManager.getProject();
    console.log(project);
    if (project.extraConfig) {
      if (project.extraConfig.askInitialinfo === false) {
        history.push(`/advices?code=${UrlCode}`);
        return;
      }
    }
    if (!data2) {
      // preload data

      props.api.getPreloadedData().then((data) => {
        setData(data);
        console.log("PreloadedData->>>>>>");
        console.log(data, "CODEE");

        // if (!data.naturalPerson) {
        //   document.getElementById("personSelector")[1].selected = true;
        //   document.getElementById("personSelector")[0].selected = false;
        //   setSelectData("2");
        // } else {
        //   document.getElementById("personSelector")[0].selected = true;
        //   document.getElementById("personSelector")[1].selected = false;
        //   setSelectData("1");
        // }

        // if (data.lastName && needsLastName) {
        //   setNeedsRedirect(true);
        //   // document.getElementById("userLastName").value = data.lastName;+
        //   document.getElementById("userLastName").disabled = true;
        //   setLastName(data.lastName);
        //   // blockDataEntry(data);
        // }
        // if (data.identification) {
        //   setNeedsRedirect(true);
        //   document.getElementById("cedulaUser").value = data.identification;
        //   document.getElementById("cedulaUser").disabled = true;
        //   // blockDataEntry(data);
        // }
        // if (data.email) {
        //   setNeedsRedirect(true);
        //   document.getElementById("correoUser").value = data.email;
        //   document.getElementById("correoUser").disabled = true;
        //   // blockDataEntry(data);
        // }
        // if (data.cellphone && needsPhone) {
        //   setNeedsRedirect(true);
        //   // document.getElementById("celularUser").value = data.cellphone;
        //   document.getElementById("celularUser").disabled = true;
        //   setPhone(data.cellphone);
        //   // blockDataEntry(data);
        // }
        // if (needsRut && data.rut && data.naturalPerson) {
        //   setNeedsRedirect(true);
        //   document.getElementById("rutUser").value = data.rut;
        //   document.getElementById("rutUser").disabled = true;

        //   // blockDataEntry(data);
        // }
        // if (data.naturalPerson === false && data.nit) {
        //   setNeedsRedirect(true);
        //   document.getElementById("nitUser").value = data.nit;
        //   document.getElementById("nitUser").disabled = true;
        //   // blockDataEntry(data);
        // }
      });
    }
  }, []);

  //needsPhone
  useEffect(() => {
    needsPhoneF();
  }, []);
  async function needsPhoneF() {
    let project = await props.api.getProject();
    if (project.hasOtp === false) {
      console.log("setNeedsPhone: False!!");
      setNeedsPhone(false);
    }
    if (project.needsAskName === true) {
      setNeedsName(true);
    }
    if (project.needsAskLastName === false) {
      setNeedsLastName(false);
    }
  }

  const final = async () => {
    //load values

    var apellido = lastName;
    var cedula = document.getElementById("cedulaUser").value;
    var celular = "";
    var correo = "";
    var nombre = name;
    var nit = "";
    var rut = "";
    if (selectdata === "2") {
      // nit = document.getElementById("nitUser").value;
    }
    if (selectdata === "1" && needsRut) {
      // rut = document.getElementById("rutUser").value;
    }
    // console.log(apellido);
    // console.log(cedula);
    // console.log(correo);

    var re = /\S+@\S+\.\S+/;
    //check values
    if (cedula === "") {
      infoMessage("Por favor completa todos los datos");
      return;
    }
    // if (selectdata === "2" && nit === "") {
    //   infoMessage("Por favor completa todos los datos");
    //   return;
    // }
    // if (selectdata === "1" && needsRut && rut === "") {
    //   infoMessage("Por favor completa todos los datos");
    //   return;
    // }

    // if (!re.test(correo)) {
    //   infoMessage("Formato de correo inválido.");
    //   return;
    // }
    // if (needsPhone) {
    //   if (celular.length !== 10 && celular.length !== 8) {
    //     infoMessage("Formato de celular inválido.");
    //     return;
    //   }
    // }

    // if (needsLastName) {
    //   if (apellido === "") {
    //     infoMessage("Ingresa tu apellido");
    //     return;
    //   }
    // }

    // if (selectdata === "2" && nit.length !== 9 && nit.length !== 10) {
    //   infoMessage("NIT invalido.");
    //   return;
    // }

    //verify nombre
    if (nombre === "") {
      infoMessage("Ingresa tu nombre");
      return;
    }
    //send data
    await props.api.addUserInitialInformation(
      cedula,
      "",
      "",
      "",
      docType,
      nombre
    );

    props.setPhoneEmail(celular, correo);

    if (selectdata === "2") {
      await props.api.sendInitUser(nit);
    }
    if (selectdata === "1" && needsRut) {
      await props.api.setRut(rut);
    }
    // props.nextPage();
    // next();
    history.push(`/advices?code=${UrlCode}`);
    //  history.push(`/final?code=${UrlCode}`);
    props.setColorB(2);
  };

  const redirectWrongData = async () => {
    try {
      var url = await props.api.getWrongDataUrl();
      window.location = url;
    } catch (e) {
      console.log(e);
    }
  };

  if (!data2) {
    return (
      <div className="container">
        <div className="row"></div>
      </div>
    );
  }

  const changeDocType = (pDocType) => {
    storageManager.setDocType(pDocType);
    setDocType(pDocType);
  };

  return (
    <div className="container recolectStart">
      {/* {index === 0 && ( */}

      <div className="row">
        <div className="col-12">
          <p className=" txt_enter">
            Es momento de<br></br> conocerte<br></br> un poco mas
          </p>
        </div>
        {/* ----------subText--------------- */}
        <div className="col-12">
          <h5 className="txt_enter2">Ingresa los siguientes datos:</h5>
        </div>
        {/* ----------INPUT APELLIDO--------------- */}
        {/* {needsLastName && (
          <div className="col-12">
            <h6 className="color-white">APELLIDO</h6>
            <input
              className="input-form"
              id="userLastName"
              maxLength="15"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            ></input>
          </div>
        )} */}

        {/* ----------INPUT NOMBRE--------------- */}

        {
          <div className="col-12">
            <h6 className="color-white">NOMBRE</h6>
            <input
              className="input-form"
              id="nombreUser2"
              maxLength="20"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></input>
          </div>
        }
        {/* ----------INPUT_2--------------- */}

        {/* Conditional Selector */}
        {/* {docTypes.length > 0 && (
          <div className="col-12" style={{ marginTop: "2vh" }}>
            <h6 className="color-white">TIPO DE DOCUMENTO</h6>
            <select
              id="docTypeSelect"
              className="select-input"
              onChange={(e) => {
                changeDocType(e.target.value);
              }}
            >
              {docTypes.map((val, i) => {
                return (
                  <option key={i} className="option-select" value={val}>
                    {val}
                  </option>
                );
              })}
            </select>
          </div>
        )} */}

        <div className="col-12">
          <h6 className="color-white"># DOCUMENTO</h6>

          <input
            id="cedulaUser"
            type="number"
            onInput={(e) => (e.target.value = e.target.value.slice(0, 10))}
            className="input-form"
          ></input>
        </div>
        {/* ----------Phone Input--------------- */}
        {/* {needsPhone && (
          <div className="col-12">
            <h6 className="color-white">CELULAR</h6>
            <input
              id="celularUser"
              type="number"
              // onInput={(e) => (e.target.value = e.target.value.slice(0, 10))}
              onChange={(e) => setPhone(e.target.value)}
              className="input-form"
              value={phone}
            ></input>
          </div>
        )} */}

        {/* ----------INPUT_4--------------- */}
        {/* <div className="col-12">
          <h6 className="color-white">CORREO</h6>

          <input
            id="correoUser"
            type="email"
            maxLength="40"
            className="input-form"
          ></input>
        </div> */}
        {/* ----------INPUT_5_WITH_SELECT--------------- */}
        {/* <div className="col-12">
          <h6 className="color-white">PERSONA</h6>
          <select
            id="personSelector"
            className="select-input"
            onChange={(e) => {
              setSelectData(e.target.value);
            }}
          >
            <option className="option-select" value="1">
              Natural
            </option>
            <option className="option-select" value="2">
              Jurídica
            </option>
          </select>
        </div> */}
        {/* ----------CONDITIONAL_OF_SELECT_OPTIONS--------------- */}
        {/* <div className="col-12">
          {selectdata === "1" && needsRut && (
            <>
              <h6 className="color-white">RUT</h6>
              <input
                id="rutUser"
                type="number"
                maxLength="40"
                className="input-form"
              ></input>{" "}
            </>
          )}
          {selectdata === "2" && (
            <>
              <h6 className="color-white">NIT</h6>
              <input
                id="nitUser"
                type="number"
                maxLength="40"
                className="input-form"
              ></input>
            </>
          )}
        </div> */}
        {/* ----------BUTTON--------------- */}
        <div className="col-12">
          <button
            type="button"
            className="btn-default btn_next"
            onClick={() => final()}
          >
            <h1>SIGUIENTE</h1>
          </button>
        </div>
        {/* {needsRedirect && (
          <div className="col-12">
            <button
              type="button"
              className="btn btn-default"
              onClick={() => redirectWrongData()}
              style={{
                borderRadius: "20px",
                backgroundColor: "transparent",
                border: "none",
                color: "WHITE",
                marginTop: "1%",
              }}
            >
              <h6 className="color-white">CORREGIR DATOS</h6>
            </button>
          </div>
        )} */}
      </div>
    </div>
  );
}
export default RecolectDataStart;
