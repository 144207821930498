import React, { useState, useEffect } from "react";
import TextFinal from "../textFinal";
import icono1 from "../../static/ICO USER.png";
import icono2 from "./../../assets/scan.png";
import icono3 from "./../../assets/video.png";
import icono4 from "../../static/ICO UBICACION.png";
import icono5 from "./../../assets/microphone.png";
import icono6 from "../../static/ICO SMS.png";
// import iconoanteia from "../static/00 PAV 01.png";
import iconoanteia from "../../assets/logo_anteia.png";
import Checkbox from "@material-ui/core/Checkbox";
import { Container, Row, Col } from "react-bootstrap";
import IntroLogo from "../IntroLogo/IntroLogo";
// import { infoMessage } from "./utils/errorHandler";
import { Link, useHistory } from "react-router-dom";
import "./TerminosIntroPage.scss";
import useQuery from "../../hooks/useQuery";
import { isMobile } from "../utils/utils";
import { infoMessage } from "../utils/ErrorHandler/errorHandler";
import alert from "./../../assets/alert.png";

function TerminosIntroPage(props) {
  let history = useHistory();
  const [index, setIndex] = useState(0);
  const [acept, setAcept] = useState(false);
  const [showadd, setShowadd] = useState(null);
  const query = useQuery();
  // console.log( new URLSearchParams(useLocation().search).get("code"), 'URL')

  const UrlCode = query.get("code");
  // console.log('UrlCode',UrlCode)
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((index) => (index < 1 ? index + 1 : index));
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // function showAlert() {
  //   //TODO: Mostrar alerta cuando no está chequeado el chulito (definir mensaje correctamente, no poner "chulito pls")
  // }
  useEffect(() => {
    if (!isMobile()) {
      history.push(`/qrFlow?code=${UrlCode}`);
    }
  }, []);

  function showAlert() {
    if (acept) {
      setShowadd(true);
      console.log("PUSHING ROUTE!!!!! " + `/intro?code=${UrlCode}`);
      history.push(`/intro?code=${UrlCode}`);
      props.setColorB(2);
    } else {
      setShowadd(false);
      infoMessage("DEBES ACEPTAR LOS TERMINOS Y CONDICIONES PARA CONTINUAR");
    }
  }

  const onChange = (e) => {
    setAcept(!acept);
    setShowadd(!showadd);
  };

  function getLogo() {
    if (props.logoUrl && props.logoUrl !== "") {
      return props.logoUrl;
    } else {
      return iconoanteia;
    }
  }

  console.log(props.logoUrl);

  return (
    <div className="App container terms-conditions">
      <div className="row">
        {/* ------------------INTRO__LOGO------------------------ */}
        {index === 0 && (
          <div>
            <IntroLogo logoUrl={props.logoUrl} />
          </div>
        )}
        {/* ------------------INTRO__TEXTO------------------------ */}
        {/*        {index === 1 && (
          // props.setColorB(2),
          <div
            className="col-12 text-intro"
            style={{
              padding: index !== 2 ? "10%" : "12%",
              color: "white",
              textAlign: "center",
              paddingLeft: "10%",
              paddingRight: "10%",
              paddingBottom: "0%",
            }}
          >
            <TextFinal
              type="h1"
              txt={
                "¡ La forma más segura para proteger tu inversión y que nadie te suplante es el video !"
              }
            />
          </div>
        )} */}
        {/* ------------------PRIMER__SCREEN------------------------ */}
        {index === 1 && (
          //  props.setColorB(1),
          <div className="col-12">
            <div className="row">
              <Row className="row_icon">
                <Col className="row_col">
                  <p>Antes de comenzar</p>
                  {props.logoUrl && props.logoUrl !== "a" && (
                    <img
                      src={props.logoUrl}
                      alt="logo_anteia"
                      className="icon_principal"
                    />
                  )}
                  {(!props.logoUrl || props.logoUrl === "a") && (
                    <img
                      src={iconoanteia}
                      alt="logo_anteia"
                      className="icon_principal"
                    />
                  )}
                  <p>necesita acceder, recolectar y almacenar datos de :</p>
                </Col>
              </Row>

              {/* -----------------------CONTAINER_1------------------------------- */}

              <Container className="container_terminos">
                <Col className="instructions">
                  {/*----------identidad------------*/}
                  <Row>
                    <Col xs={2} className="instruction_icon">
                      <img src={icono1} alt="Icono" />
                    </Col>
                    <Col className="instruction_text">
                      <p className="mb-0">Identidad</p>
                      <p> consultar la identidad y estado del dispositivo</p>
                    </Col>
                  </Row>
                  {/*----------reconocimiento------------*/}
                  {/* <Row>
                    <Col xs={2} className="instruction_icon">
                      <img src={icono2} alt="Icono" />
                    </Col>
                    <Col className="instruction_text">
                      <p style={{ marginTop: "5px" }}>
                        {" "}
                        Reconocimiento facial y datos biometricos
                      </p>
                    </Col>
                  </Row> */}
                  {/*----------video------------*/}
                  <Row>
                    <Col xs={2} className="instruction_icon">
                      <img src={icono3} alt="Icono" />
                    </Col>
                    <Col className="instruction_text">
                      <p className="mb-0">Cámara de vídeo</p>
                      <p> realizar fotografías y videos</p>
                    </Col>
                  </Row>
                  {/*----------ubicacion------------*/}
                  {/* <Row>
                    <Col xs={2} className="instruction_icon">
                      <img src={icono4} alt="Icono" />
                    </Col>
                    <Col className="instruction_text">
                      <p className="mb-0">Ubicación</p>
                      <p className="mb-0">
                        acceder a tu ubicación aproximada (basada en red)
                      </p>
                      <p>acceder a tu ubicación precisa (basada en red GPS)</p>
                    </Col>
                  </Row> */}
                  {/*----------microfono------------*/}
                  {/* <Row>
                    <Col xs={2} className="instruction_icon">
                      <img src={icono5} alt="Icono" />
                    </Col>
                    <Col className="instruction_text">
                      <p className="mb-0">Micrófono</p>
                      <p>grabar sonido</p>
                    </Col>
                  </Row> */}
                  {/*----------mensajes------------*/}
                  <Row>
                    <Col xs={2} className="instruction_icon">
                      <img src={icono6} alt="Icono" />
                    </Col>
                    <Col className="instruction_text">
                      <p className="mb-0">SMS</p>
                      <p className="mb-0"> recibir mensajes de texto (SMS)</p>
                      <p className="mb-0">enviar mensajes SMS</p>
                    </Col>
                  </Row>
                </Col>
              </Container>

              {/*  -----------------------CONTAINER_2------------------------------- */}

              <Container className="container_accepterms">
                <Row>
                  <Col xs={2}>
                    <Checkbox
                      size="medium"
                      className="checkbox_accepterms"
                      style={{
                        transform: "scale(1.5)",
                      }}
                      checked={acept}
                      onChange={onChange}
                      inputProps={{
                        "aria-label": "checkbox with default color",
                      }}
                    />
                    {console.log(acept, " state")}
                  </Col>
                  <Col className="col_accepterms">
                    <p className="text-terms" style={{ textAlign: "left" }}>
                      DOY MI CONSENTIMIENTO LIBRE, EXPRESO QUE HE SIDO INFORMADO
                      DE LOS TÉRMINOS Y CONDICIONES, POLÍTICA DE PRIVACIDAD Y
                      TRATAMIENTO DE DATOS SENSIBLES .
                      <a href="https://storage.googleapis.com/anteia-cdn/tyc/politica%20privacidad%20oct%202021.pdf">
                        (LEER MÁS)
                      </a>
                    </p>
                  </Col>
                </Row>
                {showadd === false && (
                  <div className="row-check">
                    <div className="check-msg">
                      <p>
                        DEBES DAR CHECK PARA CONTINUAR Y ACEPTAR LOS TÉRMINOS Y
                        CONDICIONES, PÓLITICA DE PRIVACIDAD Y TRATAMIENTO DE
                        DATOS SENSIBLES
                      </p>
                    </div>

                    <div className="check-msg-img">
                      <img src={alert} alt="alert" />
                    </div>
                  </div>
                )}
              </Container>

              {/*  -----------------------CONTAINER_3_BUTTONS------------------------------- */}

              <Col className="conatiner-buttons">
                <button type="button" className="btn btn-default deniet">
                  RECHAZAR
                </button>

                <button
                  type="button"
                  className="btn btn-default acept"
                  onClick={() => showAlert()}
                  // disabled={acept === false}
                  // onClick={ () => props.conexion()}
                >
                  ACEPTAR
                </button>
              </Col>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TerminosIntroPage;
