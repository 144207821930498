import React, { useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Link, useHistory } from "react-router-dom";
import "./DataDoneScreen.scss";
import { Button } from "../utils/Buttons/Buttons";

const url = "https://www.worldcomplianceforum.com/";

function DataDoneScreen(props) {
  let history = useHistory();

  return (
    <div className="row dataDone" style={{ color: "white" }}>
      <div
        className="col-12 verify"
        style={{
          padding: "1%",
          paddingBottom: "0%",
          marginTop: "10%",
          textAlign: "center",
        }}
      >
        <h2>¡Excelente!</h2>

        <div style={{ margin: "20%" }}>
          <h2 style={{ fontSize: "20px" }}>
            Tu identidad digital ha sido verificada correctamente. Disfruta del
            evento
          </h2>
        </div>
        <div>
          <img src="https://storage.googleapis.com/anteia-cdn/logos/FeladeWCFLogo_notrans.png" style={{width:"80vw"}}/>
        </div>
        <div style={{marginTop: "30%"}}>
          <Button text="Terminar" width="177px" height="5vh" onClick={() => {
            window.location.href = url
          }}/>
        </div>
      </div>
    </div>
  );
}
export default DataDoneScreen;
